import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import "../../Share/Style.css";
import AboutCard from "./AboutCard";
import HomeCard from "../../Share/HomeCard";
import PageTitle from "../../Share/PageTitle";

const AboutTwo = () => {
  const { local, serviceArray } = UseData();

  return (
    <section>
      <PageTitle title="About"></PageTitle>
      {/* End pagetitle */}

      <div className=" lg:rounded-2xl bg-white dark:bg-[#111111]">
        <div data-aos="fade">
          <div className=" pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* About page title */}
            <h2 className="after-effect after:left-52 lg:block hidden">
              About Me
            </h2>
            <div className="lg:hidden">
              {/* Sidebar personal information for mobile devices */}
              <HomeCard />
              <div className=" md:gap-10 mb-12 md:pt-[30px] items-center lg:hidden px-4">
                <h2 className="after-effect after:left-52 mb-5">About Me</h2>
                <div className="col-span-12 space-y-2.5">
                  <div className="lg:mr-16">
                    <p className="text-gray-lite  dark:text-color-910 leading-7">
                      I am a software developer with strong problem-solving skills, a passion for coding, and a results- oriented approach. 
                      My commitment to learning enables me to continuously improve my skills. I have developed various projects both as a 
                      freelancer and a full-time developer. I am proficient in creating user-friendly, high-performance, and secure 
                      applications. I work efficiently in team-oriented environments and adapt easily to changing job demands. 
                      By closely following technological trends, I focus on producing modern and sustainable solutions.
                    </p>
                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                      During project development, I utilize a variety of programming languages and technologies, including HTML, 
                      CSS (Sass), Bootstrap, JavaScript, jQuery, Node.js, React, Express.js, Next.js, PHP, .Net, MongoDB, MS SQL, 
                      MySQL, Python, and Git. My focus is on implementing sustainable coding techniques to create high-quality, 
                      scalable software solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden ">
              <div className="col-span-12 space-y-2.5">
                <div className="lg:mr-16">
                  <p className="text-gray-lite  dark:text-color-910 leading-7">
                    I am a software developer with strong problem-solving skills, a passion for coding, and a results- oriented approach. 
                    My commitment to learning enables me to continuously improve my skills. I have developed various projects both as a 
                    freelancer and a full-time developer. I am proficient in creating user-friendly, high-performance, and secure 
                    applications. I work efficiently in team-oriented environments and adapt easily to changing job demands. 
                    By closely following technological trends, I focus on producing modern and sustainable solutions.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    During project development, I utilize a variety of programming languages and technologies, including HTML, 
                    CSS (Sass), Bootstrap, JavaScript, jQuery, Node.js, React, Express.js, Next.js, PHP, .Net, MongoDB, MS SQL, 
                    MySQL, Python, and Git. My focus is on implementing sustainable coding techniques to create high-quality, 
                    scalable software solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End about descriptions */}

          <div className="  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            <h3 className="text-[35px] dark:text-white font-medium pb-5 px-4">
              Technologies I Use
            </h3>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
              {/* Experience information  */}

              {serviceArray.map((item) => (
                <AboutCard key={Math.random()} item={item} local={local} />
              ))}
            </div>
          </div>

          {/* Common Footer call here */}
          <Footer bg={"#FFFF"} />
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
