import React from "react";
import { useParams } from "react-router-dom";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import PageTitle from "../../Share/PageTitle";
import { FiCalendar, FiCode, FiFilePlus, FiExternalLink } from "react-icons/fi";
import useSlugify from "../../Hooks/useSlugify";

const PortfolioDetail = () => {
  const { title } = useParams(); // Get the slug from the URL
  const { data } = UseData(); // Get data using the custom hook
  const slugify = useSlugify; // Assign the slugify function

  // Find the project where the slugified title matches the URL param
  const project = data.find((item) => slugify(item.title) === title);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <>
    <PageTitle title={project.title} />
    <div className="bg-white lg:rounded-2xl dark:bg-[#111111] p-6">
      <h2 className="text-[#ef4060] text-6xl text-center font-bold mb-4">
        {project.title}
      </h2>
      <div className="space-y-4 my-6">
        <p className="dark:text-white flex items-center text-[15px] sm:text-lg">
          <FiFilePlus className="sm:text-lg hidden sm:block mr-2 md:text-xl" />
          Category :&nbsp;
          <span className="font-medium">{project.tag}</span>
        </p>
        <p className="dark:text-white flex items-center text-[15px] sm:text-lg">
          <FiCode className="text-lg mr-2 hidden sm:block" />
          Technology :&nbsp;
          <span className="font-medium">{project?.langages}</span>
        </p>
        <p className="dark:text-white flex items-center text-[15px] sm:text-lg">
          <FiCalendar className="text-lg mr-2 hidden sm:block" />
          Year :&nbsp;
          <span className="font-medium">{project?.client}</span>
        </p>
        <p className="dark:text-white flex items-center text-[15px] sm:text-lg">
          <FiExternalLink className="text-lg mr-2 hidden sm:block" />
          Preview :&nbsp;
          <span className="font-medium transition-all duration-300 ease-in-out hover:text-[#ef4060]">
            <a href={project?.link} target="_blank" rel="noopener noreferrer">
              {project?.linkText}
            </a>
          </span>
        </p>
      </div>
      <p className="dark:text-white text-2line font-normal text-[15px] sm:text-sm mb-4">
        {project?.description}
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-6">
        <img className="w-full h-auto object-cover rounded-xl" src={project.img} alt={project.title} />
        <img className="w-full h-auto object-cover rounded-xl" src={project.img2} alt={project.title} />
      </div>
      <Footer condition={true} bg={"#F8FBFB"} className="mt-8" />
    </div>
    </>
  );
};

export default PortfolioDetail;
