import React from "react";
import Masonry from "react-masonry-css";
import { Link } from "react-router-dom";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import PageTitle from "../../Share/PageTitle";
import useSlugify from "../../Hooks/useSlugify";
// Helper function to create a URL-friendly slug

const CommonPortfilo = ({ items }) => {
  const { local, data } = UseData();
  const slugify = useSlugify;

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 2,
    500: 1,
  };

  return (
    <>
      <PageTitle title="Portfolio" />

      <section className="bg-white lg:rounded-2xl dark:bg-[#111111]">
        <div data-aos="fade">
          <div className="container mb-8 px-4 sm:px-5 md:px-10 lg:px-[60px]">
            <div className="py-12">
              <h2 className="after-effect after:left-48 mt-12 lg:mt-0">Portfolio</h2>
            </div>

            {/* Portfolio items start */}
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {data.slice(0, items).map((item) => (
                <div
                  style={{
                    background: `${local === "dark" ? "transparent" : item?.bg}`,
                  }}
                  className="rounded-lg p-6 dark:border-[2px] border-[#212425]"
                  key={item.id}
                >
                  <div className="overflow-hidden rounded-lg">
                    <Link to={`/portfolio/${slugify(item.title)}`} className="block">
                      <img
                        className="w-full cursor-pointer transition duration-200 ease-in-out transform hover:scale-110 rounded-lg h-auto"
                        src={item?.imgSmall}
                        alt=""
                      />
                    </Link>
                  </div>
                  <span className="pt-5 text-[14px] font-normal text-gray-lite block dark:text-[#A6A6A6]">
                    {item?.tag}
                  </span>
                  <Link to={`/portfolio/${slugify(item.title)}`} className="block">
                    <h2 className="font-medium cursor-pointer text-xl duration-300 transition hover:text-[#FA5252] dark:hover:text-[#FA5252] dark:text-white mt-2">
                      {item?.title}
                    </h2>
                  </Link>
                </div>
              ))}
            </Masonry>

            {/* Portfolio items end */}
          </div>
          {/* End Portfolio */}

          {/* Common Footer call here */}
          <Footer condition={true} bg={"#F8FBFB"} />
        </div>
      </section>
    </>
  );
};

export default CommonPortfilo;
